// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');
//@import url('https://fonts.googleapis.com/css?family=Rubik');
//@import url('https://fonts.googleapis.com/css?family=Oswald');
//@import url('https://fonts.googleapis.com/css?family=Montserrat');
// Variables
//@import "vendor/lightpick";

// Bootstrap
@import "custom";
@import "~bootstrap/scss/bootstrap";

html, body {
	//height: 100%;
}

html {
	position: relative;
	min-height: 100%;
}

button.navbar-toggler {
	outline: 0 !important;
}

body {
	background: url("../../public/img/bg.png") repeat;
	> nav {
		//box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 5px;
		border-bottom: 1px $gray-400 dotted;
		//margin-bottom: 30px
	}
}

[readonly="readonly"],
[disabled="disabled"] {
	color: #6c757d;
	&:hover {
		cursor: default;
	}
}

.gradient {
	///* background: #caf2ef; */
	//background: linear-gradient(45deg, #caf2ef 0%, #c8efdb 33%, #f2baf1 66%);
	//background: linear-gradient(-10deg, #fdc0ba8f 0%, #ffe3003d 66%)
	background: linear-gradient(-10deg, #fedcd8ff 0%, #fff8c2fd 66%)
}


// FIREFOX OVERRIDE
input:required {
	box-shadow: none;
}


.alert > ul {
	margin-bottom: 0;
}


.table td, .table th {
	border-top: 1px solid #efefef;
}

//label {
//	font-size: 18px;
//}

#app {

	padding-bottom: 2rem;

	.search-form-container > form > div {
		background: rgba(255, 255, 255, 0.8);
		@include media-breakpoint-down(sm) {
			background: rgba(255, 255, 255, 1);
		}
	}


	//.website-home {
	//background: url("../../public/img/bg-foto.jpg") no-repeat 50% 50%;
	//background-size: cover;
	//min-height: calc(50vh);
	//}
	.room-list {
		a {
			&.room-list-card-txt {
				text-decoration: none;
				color: $black;
			}

			&.room-list-card-c2a {
			}
		}
	}
	.reservation-extras {
		&-block {
			width: 100%;
			@include media-breakpoint-up(md) {
				width: 50%;
				//&:nth-of-type(n+1) {
				//padding: 1rem;
				//color: rebeccapurple;
				//}
			}

			.extra-description {
				border-bottom: 1px dashed $primary;
			}

		}
	}
}

.footer-main {
	bottom: 0;
	width: 100%;

	.page-link {
		li {
			line-height: 2.5rem
		}
	}

	.button.circle {
		margin-top: .2rem;
		margin-bottom: .2rem;
		//background-color: #f6f7ff;
		border: 1px solid $primary;
		border-radius: $btn-border-radius;
		display: block;
		height: 2.5rem;
		padding: .35rem .4rem .4rem .4rem;
		width: 2.5rem;

		svg {
			width: 100%;
			height: 1rem;

			path {
				fill: $primary
			}
		}
	}
}


.card-img-overlay.darken {
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 4%, rgba(0, 0, 0, 0.45) 35%, rgba(0, 0, 0, 0.40) 65%, rgba(0, 0, 0, 0) 96%);
}

#total-reservation-cost {
	//    transition: all 3s ease 0s, border .2s ease 0s;
	//border: 2px solid $primary;
	//border-radius: 5px;
	//padding-top: 0.35rem !important;
	//min-width: 90px;
	//transform: scale(1.4) translateX(-50%) translateY(-50%);
	@include media-breakpoint-up(md) {
		position: absolute;
	}
	transform: scale(1.4) translateX(-50%) translateY(-34%);
	transform-origin: 50% 50%;
	//transform-origin: 184% 100%;
	//position: absolute via class;
	//top: 0;
	//right: 0;


	&.blink {
		color: black !important;
		//font-size: 1.2rem;
		//background-color: yellow !important;
		//background-color: $secondary !important;
		//border-radius: 50%;
		//transform-origin: 50% 50%;
		transform: scale(2) translateX(-50%) translateY(-26%);
		//animation: 0.3s ease-in 1s 1 reverse both running myBlink;
	}
}


#room-smart-search-form {
	@include media-breakpoint-up(md) {
		//border: 1px solid rgba(0, 0, 0, 0.1)
		//border: 1px solid $primary
		//box-shadow: rgba(0, 0, 0, 0.12) 0px -6px 14px;
	}
}

#smart-search-form {
	//input {
	//	max-width: 6.4rem;
	//}

	//select {
		//max-width: 1.8rem;
		//padding: 0.2rem 0.41rem;
		//padding: 0.2rem 0.32rem;
		//background-image: none
	//}
}

#book-now-form {
	//box-shadow: rgba(0, 0, 0, 0.12) 0px -6px 14px;
	//box-shadow: rgba(0, 0, 0, 0.1) 0 1px 12px
	//box-shadow: rgba(0, 0, 0, 0.1) 0 0 0;
	@include media-breakpoint-up(lg) {
		border: 1px $gray-400 dotted;
	}
}


.form-control {
	border: $input-border-width solid $input-border-color;
}

// extecter mouse over pointer using custom switches
.custom-switch .custom-control-label {
	&:hover {
		&:before, &:after {
			cursor: pointer;
		}
	}
}


@include media-breakpoint-up(sm) {
}

hr {
	border-top: $hr-height dashed $hr-height;
}


.fc-day-grid-event {
	&:hover {
		cursor: pointer;
	}
}


//.room-facility .custom-control {
//	min-width: 256px;
//}

.fas, .fal, .far, .svg-inline--fa {
	//min-width: 14px;
}

//.fab, .svg-inline--fa { // stripe reservation.index
.fab, .fa-stripe.svg-inline--fa {
	min-width: 14px;
	max-width: 32px;
}


#booking-payment-bar {
	min-height: 40px;
}

#clear-field-btn {
	top: 3.6rem;
	right: 1rem;
	font-size: 11px;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-family: monospace;
	padding: 2px 7px;
}


// SPLIDE

//.splide__pagination {
//	bottom: unset !important;
//	top: .5rem !important
//}

#check-in-out {
	@include media-breakpoint-up(sm) {
		min-width: 18rem
	}
}