@use 'sass:math';

// Color Sysyem
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #f4f4f4;
$gray-400: #ced4da;
$gray-600: #6c757d;
$gray-800: #343a40;

//$gray-300: #dee2e6 !default;
//$gray-500: #adb5bd !default;
//$gray-700: #495057 !default;
//$gray-900: #212529 !default;
//$black:    #000 !default;

$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66D9b;
//$red: #e3342f;
$orange: #f6993f;
//$yellow: #ffed4a;
//$yellow: #d8c231;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
//$black: #000000;

// TEST
$black: #1c1427;
$dark: #1c1427;
//$secondary: #ffefa1;
$secondary: #CAF2EF;

$light: #ccffbd;


// CGa palette
$yellow: #ffe631;
$magenta: #aa00aa;

//$primary:       $pink;
$primary:       $black;
//$secondary:     $gray-600;
//$secondary:     $magenta;


//$success:       $green !default;
//$info:          $cyan !default;
$warning:       $orange;
//$danger:        $red !default;

//$light:         $gray-400;

//$dark:          $gray-800 !default;





$input-color: $primary;
//$input-font-weight: 600;
$input-placeholder-color: $gray-600;
$enable-rounded: true;
$input-bg: $gray-200;
$input-border-color: $input-bg;

	// Body
//$body-bg: $gray-400;

$spacer: 1rem;

// Typography
$font-family-sans-serif: sans-serif;
//$font-family-sans-serif: "Oswald", sans-serif;
//$font-family-sans-serif:  'Rubik', sans-serif;
//$font-family-sans-serif:  'Inconsolata', sans-serif;
//$font-family-sans-serif:  'Oswald', sans-serif;
//$font-family-sans-serif:  'Montserrat', serif;
//$font-size-base: 0.9rem;
//$font-family-monospace:       "Rubik", Consolas, "Liberation Mono", "Courier New", monospace;
//$font-family-monospace:       "Inconsolata", Consolas, "Liberation Mono", "Courier New", monospace;
//$font-family-monospace:       "Source Code Pro", Consolas, "Liberation Mono", "Courier New", monospace;
//$font-family-monospace:       "Roboto Mono", Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-monospace:       monospace;
$font-family-base:            $font-family-monospace;
//$font-weight-normal:          400 !default;

//$headings-margin-bottom:      $spacer / 2 !default;
$headings-font-family:        $font-family-sans-serif;
//$headings-font-weight:        500 !default;
//$headings-line-height:        1.2 !default;
//$headings-color:              $secondary;

$line-height-base: 1.5;











// Borders
$hr-border-color: $primary;
$border-radius: 4px;
//$hr-margin-y: $spacer*3;

// Links
//
// Style anchor elements.
$link-color:  $primary;

//$link-color:                $black;
//$link-decoration:           none !default;
$link-hover-color:          darken($link-color, 50%);
//$link-hover-decoration:     underline !default;


// Navbar
//$navbar-light-color: rgba($pink, .5);
// MIEI
$navbar-padding-y:                  $spacer;
// END MIEI

//$nav-link-padding-x:                $spacer;
$nav-link-padding-y:                $spacer;
$navbar-nav-link-padding-x:			  $spacer;


$navbar-dark-color:                 #FFF;
//$navbar-dark-hover-color:           rgba($white, .75);
//$navbar-dark-active-color:          $white;
//$navbar-dark-disabled-color:        rgba($white, .25);
//$navbar-dark-toggler-border-color:  rgba($white, .1);
//
//$navbar-light-color:                rgba($black, .5);
//$navbar-light-hover-color:          rgba($black, .7);
//$navbar-light-active-color:         rgba($black, .9);
//$navbar-light-disabled-color:       rgba($black, .3);
//$navbar-light-toggler-border-color: rgba($black, .1);
//
//$navbar-light-brand-color:                $navbar-light-active-color;
//$navbar-light-brand-hover-color:          $navbar-light-active-color;
//$navbar-dark-brand-color:                 $navbar-dark-active-color ;
//$navbar-dark-brand-hover-color:           $navbar-dark-active-color ;



$navbar-light-color:                $primary;
$navbar-light-hover-color:          rgba($primary, .7);
$navbar-light-active-color:         rgba($primary, .9);
$navbar-light-disabled-color:       rgba($primary, .3);
//$navbar-light-toggler-icon-bg:      str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"), "#", "%23") !default;
$navbar-light-toggler-border-color: $primary;



// Alerts
$alert-margin-bottom:  0;


// Cards
//$card-spacer-y:                     .5rem;
$card-spacer-x:                     0;
$card-border-width:                 0;
$card-cap-bg:                       transpatent;
//$card-cap-color:                    null !default;
//$card-color:                        null !default;
$card-bg:                           transpatent;

//$card-border-radius:                $border-radius ;
//$card-border-color:                 rgba($black, .125);


// Modals
$modal-xl:                          1280px;
//$modal-title-line-height:           1;


// Buttons + Forms
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y:         0.375rem;
$input-btn-padding-x:         0.9375rem; //
//$input-btn-padding-y:         .375rem !default;
//$input-btn-padding-x:         .75rem !default;
$btn-border-width: 2px;

//$border-radius:               .25rem !default;
//
//$border-radius-sm:            .2rem !default;
//$border-radius-lg:            .3rem !default;
//$border-radius-pill:          50rem !default;

$btn-border-radius:           50px;
$btn-border-radius-sm:        $btn-border-radius;
$btn-border-radius-lg:        $btn-border-radius;

// MIEI
//$input-btn-padding-y:         .175rem;
//$input-btn-padding-x:         1.375rem;
//$label-margin-bottom:         0;
// END MIEI


// Grid breakpoints
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        //xl: 1200px
        xl: 1280px
);

// Grid containers
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        //xl: 1140px
        //xl: 1200px
        xl: 1400px
);

// Grid columns
// Set the number of columns and specify the width of the gutters.
//$grid-columns:                12 !default;
$grid-gutter-width:           60px !default;






//$enable-shadows:                              true;
//$input-box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;




$custom-control-gutter:                 1rem;
//$custom-control-spacer-x:               1rem !default;
//
$custom-control-indicator-size:         1.12rem;
//$custom-control-indicator-bg:           $input-bg !default;

//$custom-control-indicator-bg-size:      50% 50% !default;
//$custom-control-indicator-box-shadow:   $input-box-shadow !default;
//$custom-control-indicator-border-color: $gray-500 !default;
$custom-control-indicator-border-width: 2px;
//
//$custom-control-indicator-disabled-bg:          $input-disabled-bg !default;
//$custom-control-label-disabled-color:           $gray-600 !default;
//
//$custom-control-indicator-checked-color:        $component-active-color !default;
//$custom-control-indicator-checked-bg:           $component-active-bg !default;
//$custom-control-indicator-checked-disabled-bg:  rgba(theme-color("primary"), .5) !default;
//$custom-control-indicator-checked-box-shadow:   none !default;
//$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg !default;
//
//$custom-control-indicator-focus-box-shadow:     $input-focus-box-shadow !default;
//$custom-control-indicator-focus-border-color:   $input-focus-border-color !default;
//
//$custom-control-indicator-active-color:         $component-active-color !default;
//$custom-control-indicator-active-bg:            lighten($component-active-bg, 35%) !default;
//$custom-control-indicator-active-box-shadow:    none !default;
//$custom-control-indicator-active-border-color:  $custom-control-indicator-active-bg !default;
//
//
//$custom-switch-width:                           $custom-control-indicator-size * 1.75 !default;
//$custom-switch-indicator-border-radius:         math.div($custom-control-indicator-size, 2) !default;
//$custom-switch-indicator-size:                  calc(#{$custom-control-indicator-size} - #{$custom-control-indicator-border-width * 4}) !default;
$custom-control-indicator-border-color: $primary;

// Tooltips

$tooltip-font-size:                 12px;
$tooltip-max-width:                 400px;

$tooltip-color:                     white;

//$tooltip-bg:                        $primary;
$tooltip-bg:                        $secondary;

//$tooltip-border-radius:             $border-radius !default;
//$tooltip-opacity:                   .9 !default;
//$tooltip-padding-y:                 .25rem !default;
//$tooltip-padding-x:                 .5rem !default;
//$tooltip-margin:                    0 !default;

//$tooltip-arrow-width:               .8rem !default;
//$tooltip-arrow-height:              .4rem !default;
//$tooltip-arrow-color:               $tooltip-bg !default;



//$zindex-sticky:                     1020 !default;
$zindex-sticky:                     999;
//$zindex-fixed:                      1030 !default;






//
//$input-group-addon-font-weight:         $input-font-weight;
//$input-group-addon-color:               $input-color;
//$input-group-addon-bg:                  $white;
//$input-group-addon-border-color:        $white;


:root {
	/* change background color for .container__months */
	//--litepicker-container-months-color-bg: #333 !important;
	--litepicker-is-start-color-bg: #1c1427 !important;
	--litepicker-is-end-color-bg: #1c1427 !important;
	--litepicker-is-in-range-color: #f4f4f4 !important;
	--litepicker-day-color-hover: #1c1427 !important;
}
.litepicker .container__days .day-item.is-locked {
	//text-decoration: line-through;
}